var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ManageCollaborateurs"},[_c('loading',{attrs:{"active":_vm.isLoading,"can-cancel":false,"is-full-page":true,"color":"black"},on:{"update:active":function($event){_vm.isLoading=$event}}}),_c('CRow',[_c('CCol',{attrs:{"sm":"6"}},[_c('h1',[_vm._v(" Vos collaborateurs ")])]),_c('CCol',{staticClass:"text-right align-self-center",attrs:{"sm":"6"}},[((_vm.companyStripeProductId == _vm.stripeSmallProductId && _vm.collaborateurs.length < 10) || (_vm.companyStripeProductId == _vm.stripeMediumProductId && _vm.collaborateurs.length < 30))?_c('CButton',{attrs:{"size":"sm","shape":"pill","color":"outline-success"},on:{"click":function($event){return _vm.$router.push('/administration/add-collaborateur')}}},[_c('CIcon',{attrs:{"name":"cil-plus"}}),_vm._v(" Ajouter un nouveau collaborateur ")],1):_c('CButton',{attrs:{"size":"sm","shape":"pill","disabled":true,"color":"outline-dark"}},[_vm._v(" Votre abonnement ne vous permet pas d'ajouter de nouveaux collaborateurs ")])],1)],1),_c('CCard',{staticClass:"mt-3"},[_c('CCardHeader',[_c('strong',[_vm._v(_vm._s(_vm.collaborateurs.length)+" collaborateurs actifs "),(_vm.companyStripeProductId == _vm.stripeSoloProductId || (_vm.companyStripeProductId == _vm.stripeSmallProductId && _vm.collaborateurs.length >= 10) || (_vm.companyStripeProductId == _vm.stripeMediumProductId && _vm.collaborateurs.length >= 30))?_c('span',{staticClass:"text-danger"},[_vm._v(" - Limite de votre abonnement atteinte ")]):_vm._e()])]),_c('CCardBody',[_c('CDataTable',{staticClass:"cursor-table",attrs:{"hover":"","items":_vm.collaborateursRender,"fields":_vm.collaborateursRenderFields},on:{"row-clicked":_vm.goToCollaborateurPage},scopedSlots:_vm._u([{key:"full_name",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"align-middle"},[_vm._v(" "+_vm._s(item.full_name)+" ")])]}},{key:"email",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"align-middle"},[_vm._v(" "+_vm._s(item.email)+" ")])]}},{key:"phone_number",fn:function(ref){
var item = ref.item;
return [(item.phone_number)?_c('td',{staticClass:"align-middle"},[_vm._v(" +"+_vm._s(_vm.returnPhoneDial(item.phone_country_code))+" "+_vm._s(item.phone_number)+" ")]):_c('td',{staticClass:"align-middle"},[_c('em',[_vm._v("Non renseigné")])])]}},{key:"birthdate",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"align-middle"},[_c('span',[_vm._v(_vm._s(_vm.$dayjs(item.birthdate).format('DD/MM/YYYY')))])])]}},{key:"permissions",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"align-middle text-center"},[(_vm.userArrayContains(item.groups, 'MANAGER'))?_c('span',[_c('CBadge',{attrs:{"color":"danger"}},[_vm._v("Manager")])],1):_vm._e(),_c('br'),(_vm.userArrayContains(item.groups, 'ADMIN'))?_c('span',[_c('CBadge',{attrs:{"color":"info"}},[_vm._v("Administrateur")])],1):_vm._e(),_c('br'),(_vm.userArrayContains(item.groups, 'BOSS'))?_c('span',[_c('CBadge',{attrs:{"color":"success"}},[_vm._v("Super-administrateur")])],1):_vm._e()])]}}])}),_c('CRow',[_c('CCol',[_c('p',[_vm._v(" ❓ Vous pouvez accéder au détail des rôles en "),_c('router-link',{attrs:{"to":"/aide/role","target":"_blank"}},[_vm._v("cliquant ici")])],1)])],1)],1)],1),_c('CCard',[_c('CCardHeader',[_c('strong',[_vm._v("Collaborateurs inactifs")])]),_c('CCardBody',[_c('CDataTable',{staticClass:"cursor-table",attrs:{"hover":"","items":_vm.inactiveCollaborateursRender,"fields":_vm.inactiveCollaborateursRenderFields,"noItemsView":{ noResults: "Aucun résultat", noItems: "Aucun utilisateur inactif" }},on:{"row-clicked":_vm.goToCollaborateurPage},scopedSlots:_vm._u([{key:"phone_number",fn:function(ref){
var item = ref.item;
return [(item.phone_number)?_c('td',[_vm._v(" +"+_vm._s(_vm.returnPhoneDial(item.phone_country_code))+" "+_vm._s(item.phone_number)+" ")]):_c('td',[_c('em',[_vm._v("Non renseigné")])])]}},{key:"birthdate",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('span',[_vm._v(_vm._s(_vm.$dayjs(item.birthdate).format('DD/MM/YYYY')))])])]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }