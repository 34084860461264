<template>
  <div class="ManageCollaborateurs">


    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="true"
      color="black">
    </loading>

    
    <CRow>
      <CCol sm="6">
        <h1> Vos collaborateurs </h1>
      </CCol>
      <CCol class="text-right align-self-center" sm="6">
        <CButton
          v-if="(companyStripeProductId == stripeSmallProductId && collaborateurs.length < 10) || (companyStripeProductId == stripeMediumProductId && collaborateurs.length < 30)"
          @click="$router.push('/administration/add-collaborateur')"
          size="sm"
          shape="pill"
          color="outline-success">
            <CIcon name="cil-plus"/> Ajouter un nouveau collaborateur
        </CButton>
        <CButton
          v-else
          size="sm"
          shape="pill"
          :disabled="true"
          color="outline-dark">
            Votre abonnement ne vous permet pas d'ajouter de nouveaux collaborateurs
        </CButton>
      </CCol>
    </CRow>
    
    <CCard class="mt-3">
      <CCardHeader>
        <strong>{{collaborateurs.length}} collaborateurs actifs 
          <span class="text-danger" v-if="companyStripeProductId == stripeSoloProductId || (companyStripeProductId == stripeSmallProductId && collaborateurs.length >= 10) || (companyStripeProductId == stripeMediumProductId && collaborateurs.length >= 30)">
            - Limite de votre abonnement atteinte
          </span>
        </strong>
      </CCardHeader>
      <CCardBody>
        <CDataTable
          class="cursor-table"
          hover
          :items="collaborateursRender"
          :fields="collaborateursRenderFields"
          @row-clicked="goToCollaborateurPage"
        >
        <template #full_name="{item}">
          <td class="align-middle">
            {{item.full_name}}
          </td>
        </template>
        <template #email="{item}">
          <td class="align-middle">
            {{item.email}}
          </td>
        </template>
        <template  #phone_number="{item}">
          <td class="align-middle" v-if="item.phone_number">
            +{{returnPhoneDial(item.phone_country_code)}} {{item.phone_number}}
          </td>
          <td class="align-middle" v-else>
            <em>Non renseigné</em>
          </td>
        </template>
        <template #birthdate="{item}">
          <td class="align-middle">
           <span>{{$dayjs(item.birthdate).format('DD/MM/YYYY')}}</span>
          </td>
        </template>
        <template #permissions="{item}">
          <td class="align-middle text-center">
           <span v-if="userArrayContains(item.groups, 'MANAGER')"><CBadge color="danger">Manager</CBadge></span><br>
           <span v-if="userArrayContains(item.groups, 'ADMIN')"><CBadge color="info">Administrateur</CBadge></span><br>
           <span v-if="userArrayContains(item.groups, 'BOSS')"><CBadge color="success">Super-administrateur</CBadge></span>
          </td>
        </template>
        </CDataTable>
        
        <CRow>
          <CCol>
            <p>
              ❓ Vous pouvez accéder au détail des rôles en <router-link to='/aide/role' target="_blank">cliquant ici</router-link>
            </p>
              
          </CCol>
        </CRow>
        
      </CCardBody>
    </CCard>

    <CCard>
      <CCardHeader>
        <strong>Collaborateurs inactifs</strong>
      </CCardHeader>
      <CCardBody>
        <CDataTable
          class="cursor-table"
          hover
          :items="inactiveCollaborateursRender"
          :fields="inactiveCollaborateursRenderFields"
          @row-clicked="goToCollaborateurPage"
          :noItemsView='{ noResults: "Aucun résultat", noItems: "Aucun utilisateur inactif" }'
        >
        
        <template #phone_number="{item}">
          <td v-if="item.phone_number">
            +{{returnPhoneDial(item.phone_country_code)}} {{item.phone_number}}
          </td>
          <td v-else>
            <em>Non renseigné</em>
          </td>
        </template>
        <template #birthdate="{item}">
          <td>
           <span>{{$dayjs(item.birthdate).format('DD/MM/YYYY')}}</span>
          </td>
        </template>
        </CDataTable>
      </CCardBody>
    </CCard>

  </div>
</template>

<script>

import { APIAdminConnected } from '@/api/APIAdminConnected'
import { APIUserConnected } from '@/api/APIUserConnected'

import { stripeSoloProduct, stripeSmallProduct, stripeMediumProduct } from '@/variables/localVariables'

import renderMixins from '@/mixins/renderMixins'

import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';


const apiAdminConnected = new APIAdminConnected()
const apiUserConnected = new APIUserConnected()

export default {
  name: 'ManageCollaborateurs',
  components: {
    Loading
  },
  mixins: [
    renderMixins,
  ],
  data: function () {
    return {
      
      stripeSoloProductId: stripeSoloProduct,
      stripeSmallProductId: stripeSmallProduct,
      stripeMediumProductId: stripeMediumProduct,

      isCollaborateursLoading: true,
      collaborateurs: [],
      collaborateursRender: [],
      collaborateursRenderFields: [
        { key: "full_name", label: "Collaborateur", tdClass: 'ui-helper-center', sortable: true},
        { key: "email", label: "Email", tdClass: 'ui-helper-center'},
        { key: "phone_number", label: "Numéro de téléphone", tdClass: 'ui-helper-center'},
        { key: "birthdate", label: "Date de naissance", tdClass: 'ui-helper-center' },
        { key: "permissions", label: "Permissions", tdClass: 'ui-helper-center' },
      ],

      isInactiveCollaborateursLoading: true,
      inactiveCollaborateurs: [],
      inactiveCollaborateursRender: [],
      inactiveCollaborateursRenderFields: [
        { key: "full_name", label: "Collaborateur", tdClass: 'ui-helper-center', sortable: true},
        { key: "email", label: "Email", tdClass: 'ui-helper-center'},
        { key: "phone_number", label: "Numéro de téléphone", tdClass: 'ui-helper-center'},
        { key: "birthdate", label: "Date de naissance", tdClass: 'ui-helper-center' },
      ],

    }
  },
  computed: {
    token () {
      return this.$store.state.auth.token
    },
    isLoading () {
      if (this.isCollaborateursLoading || this.isInactiveCollaborateursLoading) {
        return true
      }
      return false
    },
    companyStripeProductId () {
      return this.$store.state.user.companyStripeProductId
    }
  },
  created: function() {
    this.$dayjs.locale('fr')
    this.getAllCollaborateurs()
    this.getAllInactiveCollaborateurs()
  },
  watch: {
    collaborateurs: function (newCollaborateurs) {
      if (newCollaborateurs.length == 0) {
        this.collaborateursRender = []
      }
      else {
        var final_array = []

        for (var i = 0; i < newCollaborateurs.length; i++) {
          final_array.push(
            {
              'user_id': newCollaborateurs[i].user.id,
              'groups': newCollaborateurs[i].user.groups,
              'id': newCollaborateurs[i].id,
              'sex': newCollaborateurs[i].sex,
              'fonction': newCollaborateurs[i].fonction,
              'taux_horaire': newCollaborateurs[i].taux_horaire,
              'first_name': newCollaborateurs[i].user.first_name,
              'last_name': newCollaborateurs[i].user.last_name,
              'full_name': newCollaborateurs[i].full_name,
              'birthdate': newCollaborateurs[i].birthdate,
              'email': newCollaborateurs[i].user.username,
              'phone_country_code': newCollaborateurs[i].phone_country_code,
              'phone_number': newCollaborateurs[i].phone_number,
            }
          )
        }
        this.collaborateursRender = final_array
      }
    },
    inactiveCollaborateurs: function (newInactiveCollaborateurs) {
      if (newInactiveCollaborateurs.length == 0) {
        this.inactiveCollaborateursRender = []
      }
      else {
        var final_array = []

        for (var i = 0; i < newInactiveCollaborateurs.length; i++) {
          final_array.push(
            {
              'user_id': newInactiveCollaborateurs[i].user.id,
              'groups': newInactiveCollaborateurs[i].user.groups,
              'id': newInactiveCollaborateurs[i].id,
              'sex': newInactiveCollaborateurs[i].sex,
              'fonction': newInactiveCollaborateurs[i].fonction,
              'taux_horaire': newInactiveCollaborateurs[i].taux_horaire,
              'first_name': newInactiveCollaborateurs[i].user.first_name,
              'last_name': newInactiveCollaborateurs[i].user.last_name,
              'full_name': newInactiveCollaborateurs[i].full_name,
              'birthdate': newInactiveCollaborateurs[i].birthdate,
              'email': newInactiveCollaborateurs[i].user.username,
              'phone_country_code': newInactiveCollaborateurs[i].phone_country_code,
              'phone_number': newInactiveCollaborateurs[i].phone_number,
            }
          )
        }
        this.inactiveCollaborateursRender = final_array
      }
    },
  },
  methods: {
    getAllCollaborateurs () {
      this.isCollaborateursLoading = true
      apiUserConnected.getAllCollaborateurs(this.token)
      .then((result) => {
        this.collaborateurs = result.data
      })
      .catch(() => {
        this.$store.commit('openGlobalErrorModal')
      })
      .finally(() => {
        this.isCollaborateursLoading = false
      })
    },

    getAllInactiveCollaborateurs () {
      this.isInactiveCollaborateursLoading = true
      apiAdminConnected.getAllInactiveCollaborateurs(this.token)
      .then((result) => {
        this.inactiveCollaborateurs = result.data
      })
      .catch(() => {
        this.$store.commit('openGlobalErrorModal')
      })
      .finally(() => {
        this.isInactiveCollaborateursLoading = false
      })
    },

    goToCollaborateurPage(item) {
      this.$router.push({ name: 'Votre collaborateur', params: { id: item.id } })
    }

  }
}
</script>

<style>
.minWidthTd {
   white-space: nowrap;
}
.ui-helper-center {
    text-align: center;
}
.table thead th {
  vertical-align: middle !important;
}
.cursor-table tr {
  cursor: pointer;
}
</style>
